export const metaConfig = {
    '/': {
      title: 'Choose The Best Wall Paint Company In India For Best Paints',
      description: ' Explore Our Range of Water-Based and Rust-Proof paints From Revlon Paints. Check Out And Buy Now',
      keywords: 'Best Wall Paint Company In India, Resistant Paint, Best exterior paint, Water-based primers, Enamel paint colours,',
    },
    '/about': {
        title: 'Oil-Based Enamel & Wall Paints - Top Paint Companies In India',
        description: 'Revlon Paints is a leading wall painting company in India, renowned for its premium-quality products and innovative solutions. It offers a wide range of paints that combine durability, aesthetics, and eco-friendliness to enhance every space.',
        keywords: 'Wall paint Company in India, best exterior paint, Best quality paint, Buy Paint Online, Order paint online',
      },
      '/interior-emulsions': {
        title: 'Quality Interior Emulsion Paint: Transform Your Home Today',
        description: ' Explore our quality interior emulsion paints that rejuvenate your living space. Perfect for giving your walls a fresh, vibrant look with lasting durability.',
        keywords: 'Emulsion paint, Interior wall paint, Paint price, Best interior paint, Emulsion paint price',
      },
      '/interior-emulsions/trend': {
        title: 'Best Interior Emulsion Paints: Brighten Your Living Space',
        description: '  Brighten up your home with our vibrant interior emulsion paints. Discover eco-friendly options that add elegance and style to your interiors today.',
        keywords: 'Interior Emulsion Paints ',
      },
      '/interior-emulsions/revlon-luxury': {
        title: 'Upgrade Your Home with Water-based wall Paint.',
        description: 'Explore the water-based wall paint interior on the market. Quality and affordability come together to refresh your living space beautifully.',
        keywords: 'Water-based wall paint',
      },
      '/interior-emulsions/rapid': {
        title: 'Interior Emulsion: Durable, Stylish Eco-Friendly & Water-Resistant paints',
        description: 'Upgrade your home decor with our superior interior emulsion paints. Enjoy a perfect finish that enhances your indoor spaces remarkable.',
        keywords: 'Water resistant paint',
      },
      '/exterior-emulsions': {
        title: 'Transform Your Home with Our Acrylic Exterior Emulsion Paint',
        description: 'Explore our quality acrylic exterior emulsion paints that enhance your homes beauty while providing lasting protection against the elements.',
        keywords: 'acrylic exterior paint, Water-based acrylic',
      },
      '/exterior-emulsions/cosmic': {
        title: 'Vibrant Exterior Emulsion Paints for Walls of All Home Style',
        description: ' Exterior emulsion paints For Walls offer stylish and protective solutions for your home. Choose from a variety of colors that elevate your outdoor spaces beautifully.',
        keywords: 'Emulsion paint for walls',
      },
      '/exterior-emulsions/canny': {
        title: 'Exterior Emulsion: Stylish and Protective for Your Home',
        description: ' Discover the best exterior emulsion paints designed for durability. Perfect for enhancing your homes curb appeal and resisting weather conditions effectively.',
        keywords: 'Luxury paint',
      },
      '/exterior-emulsions/polaris': {
        title: 'Exterior Emulsion: Perfect Finish for Outdoor Walls',
        description: ' Our long-lasting exterior emulsion paints are perfect for harsh weather. Protect your home with styles and colors that ensure durability and elegance.',
        keywords: 'Paint your home',
      },
      '/exterior-emulsions/legend': {
        title: 'Long-Lasting & Best Paint for Exteriors Protects From Harsh Weather',
        description: 'Upgrade your homes Look with our Best Paint for Exterior Walls. Enjoy a stunning finish that protects and enhances your property’s look.',
        keywords: 'Best paint for exterior walls',
      },
      '/primers': {
        title: 'Durable Water-Based Wall Primer Paints for Your Walls',
        description: 'Explore our water-based Wall primers for all surfaces. Ensure smooth application and long-lasting results.',
        keywords: 'Wall Primer, Primers For Walls, ',
      },
      '/primers/vision': {
        title: 'Premium Water-Based Primers by Revlon Paints',
        description: 'Achieve Flawless Finishes With Our White Primer Paint For Walls. Ideal For All Surfaces, Our Primers Enhance Durability And Color Vibrancy For Any Paint Job.',
        keywords: 'White Primer Paint for Walls',
      },
      '/primers/leo-basic': {
        title: 'Seal and Protect: Quality Water-Based Primers Available',
        description: 'Achieve flawless finishes with our best primers for walls. Ideal for all surfaces, our primers enhance durability and color vibrancy for any paint job.',
        keywords: 'Best Primer for Walls',
      },
      '/primers/venus': {
        title: ' Best Exterior Wall Primer Application for Longer Lasting Paint',
        description: 'Quality Exterior Wall primers for every surface type. Prepare your walls for lasting beauty and durability with Nebulon Paints. Discover more online.',
        keywords: 'Exterior Wall Primer',
      },
      '/primers/champion': {
        title: 'Check Out The Best Base Primer For Walls',
        description: 'Achieve a high-quality finish with Revlon’s Best primer For Walls. Perfect for maximizing the performance of your paint and ensuring a uniform surface.',
        keywords: 'Best Primer For Walls',
      },
      '/enamels': {
        title: 'Oil-Based Enamel Paints for Stunning Metal Finishes',
        description: 'Transform your projects with our high-gloss oil-based enamel paints. Designed for metal, they offer exceptional durability and stunning finishes!',
        keywords: 'Oil-Based Enamels, Synthetic Paints',
      },
      '/enamels/rado': {
        title: 'Anti-Rust Oil-Based Enamel Paint: Long-Lasting Shine',
        description: 'Discover premium oil-based enamel paints perfect for metal surfaces. Durable, anti-rust formulations ensure lasting beauty and protection.',
        keywords: 'Oil-Based Enamel Paints',
      },
      '/enamels/pride': {
        title: 'Synthetic Enamel Paints for Stunning Metal Finishes',
        description: 'Discover premium high-gloss oil-based enamel paints perfect for metal surfaces. Durable, anti-rust formulations ensure lasting beauty and protection.',
        keywords: 'Synthetic enamel',
      },
      '/enamels/lennox': {
        title: 'Weather-Resistant & Anti-rust Paints for Metal Surfaces',
        description: 'Choose our oil Primer for a brilliant high-gloss floor finish. Durable and available in a range of stunning colours!',
        keywords: 'Oil primer',
      },
      '/enamels/glance': {
        title: 'Redefine Your Space with Premium Synthetic Enamel Paint',
        description: 'Our synthetic enamel paints offer exceptional quality and high-gloss finishes for metal surfaces. Anti-rust properties make them ideal for any project',
        keywords: 'Synthetic paint',
      },
      '/enamels/marine': {
        title: 'Vibrant Colors: Oil-Based Enamel Paints for Every Metal',
        description: ' Explore our range of oil-based enamel paints designed for metal. Enjoy high-gloss finishes and anti-rust benefits for beautiful, durable results!',
        keywords: 'Paint for metal',
      },
      '/enamels/loyal': {
        title: 'Anti-Rust Oil-Based Enamel Paint: Long-Lasting Shine',
        description: 'Achieve stunning high-gloss finishes for metal with our oil-based enamel paints. Long-lasting, anti-rust protection you can rely on!',
        keywords: 'High gloss paints',
      },
      '/special-products': {
        title: 'Innovative Special Products for Unique Paint Applications ',
        description: 'Explore our range of special products designed for unique needs, delivering exceptional performance and quality for diverse surfaces.',
        keywords: 'Paint Products',
      },
      '/special-products/aerial': {
        title: 'Effective Damp Proof Solutions for Your Home',
        description: 'Protect your walls from moisture with our damp proof products, designed to prevent water damage and mold for healthier living spaces.',
        keywords: 'Water-proofing paint for walls',
      },
      '/special-products/climate-guard': {
        title: 'Reliable Weather Guard Paints for All Seasons',
        description: 'Shield your surfaces from the elements with our weather guard paints, offering ultimate protection and durability for exterior applications.',
        keywords: 'Acrylic Exterior Emulsion',
      },
      '/special-products/majestic': {
        title: 'Premium Wall Putty for Perfect Finishes',
        description: 'Achieve a flawless surface for painting with our premium wall putty, ensuring smoothness and durability for long-lasting results',
        keywords: 'Best paint Primer',
      },
      '/blogs': {
        title: 'Premium Oil-Based Enamel & High-Gloss Paints for Interiors',
        description: 'Shop high-quality oil-based enamel paints, interior & exterior wall paints, and high-gloss finishes. Find the perfect paint for your project today!',
        keywords: 'Paint Products, Oil-Based Enamel Paints, High-gloss paints, Interior Wall Paint, Exterior Wall Paint, ',
      },
      '/Blogs/which-paint-is-best-for-homes-in-india': {
        title: 'Premium Oil-Based Enamel & High-Gloss Paints for Interiors',
        description: 'Shop high-quality oil-based enamel paints, interior & exterior wall paints, and high-gloss finishes. Find the perfect paint for your project today!',
        keywords: 'Paint Products, Oil-Based Enamel Paints, High-gloss paints, Interior Wall Paint, Exterior Wall Paint, ',
      },
      
  };
  