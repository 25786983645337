import React, { useState, useEffect } from 'react';
import '../../App.css';
import logo from '../../Assets/logo.png'
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css'

const Navbar = () => {

  const location = useLocation();

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  (
    <div className="header-contact-button-unique">
      <a href="tel:+919845400269" className="contact-btn-unique">Contact Us</a>
    </div>
  );

  return (
    <div>
      <header style={{paddingTop: "20px"}} className={`header ${scrolled ? 'scrolled' : ''}`}>
        <div className="header-top">
          <div className="container-fluid-1">
            <div className="header-top-flex">
              <div className="headre-nav">
                <div className="headre-right">
                  <div className="nav-primary">
                    <ul>
                      <li className={location.pathname === '/' ? 'active' : ''}>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li className={location.pathname === '/about' ? 'active' : ''}>
                        <NavLink to="/about">About</NavLink>
                      </li>
                      <li className={location.pathname === '/aurbrands' ? 'active' : ''}>
                        <NavLink to="/blogs">Blogs</NavLink>
                      </li>
                      <li className={location.pathname === '/Dealer-enquiry' ? 'active' : ''}>
                        <NavLink to="/dealer-enquiry">Dealer Enquiry</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="headre-nav">
                <div className="headre-right">
                  <div className="nav-primary">
                 
                    <ul>
                    <li><a href="https://www.facebook.com/profile.php?id=61571900744787"><i className="icon-2"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/105612663/admin/dashboard/"><i className="icon-4"></i></a></li>
                      <li className={location.pathname === '/About' ? 'active' : ''}>
                        <NavLink to="/download-catalog">Download Catalog</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white bodybg" style={{ display: "flex" }}>
              <div className="logo">
                <a href="/"><img src={logo} alt="Logo" /></a>
              </div>
              <div className="headre-nav">
                <div className="headre-right">
                  <div className="nav-primary">
                    <ul>
                      <li className="menu-item-has-children">
                        <NavLink to="/interior-emulsions">Interior Emulsions</NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="/interior-emulsions/trend"><b>Trend</b> - Classic Interior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/interior-emulsions/revlon-luxury"><b>Revlon</b> - Luxury Interior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/interior-emulsions/rapid"><b>Rapid</b> - Premium Interior</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <NavLink to="/exterior-emulsions">Exterior Emulsions</NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="/exterior-emulsions/cosmic"><b>Cosmic</b> - Classic Interior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/exterior-emulsions/canny"><b>Canny</b> - Luxury Interior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/exterior-emulsions/polaris"><b>Polaris</b> - Premium Interior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/exterior-emulsions/legend"><b>Legend</b> - Multi</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <NavLink to="/primers">Primers</NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="/primers/vision"><b>Vision</b> - Interior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/primers/leo-basic"><b>Leo</b> - Basic</NavLink>
                          </li>
                          <li>
                            <NavLink to="/primers/venus"><b>Venus</b> - Exterior</NavLink>
                          </li>
                          <li>
                            <NavLink to="/primers/champion"><b>Champion</b> - Multi</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <NavLink to="/enamels">Enamels-Oil-Based</NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="/enamels/rado"><b>Rado</b> - Pu Enamel</NavLink>
                          </li>
                          <li>
                            <NavLink to="/enamels/pride"><b>Pride</b> - Wood Primer</NavLink>
                          </li>
                          <li>
                            <NavLink to="/enamels/lennox"><b>Lennox</b> - Floor coat red Primer</NavLink>
                          </li>
                          <li>
                            <NavLink to="/enamels/glance"><b>Glance</b> - Synthatic Enamel </NavLink>
                          </li>
                          <li>
                            <NavLink to="/enamels/marine"><b>Marine</b> - Zinc Yellow Chromate Primer</NavLink>
                          </li>
                          <li>
                            <NavLink to="/enamels/loyal"><b>Loyal</b> - Red Oxide Metal Primer</NavLink>
                          </li>

                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <NavLink to="/special-products">Special-Products</NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="/special-products/aerial"><b>Aerial</b> - Exterior Damp Proof</NavLink>
                          </li>
                          <li>
                            <NavLink to="/special-products/climate-guard"><b>Climate Guard</b> - Exterior Weather Guard</NavLink>
                          </li>
                          <li>
                            <NavLink to="/special-products/majestic"><b>Majestic</b> - Wall Putty White Cement Based</NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="headre-nav"
                style={{ marginLeft: "auto", display: "flex", alignItems: "center", padding: "0 0 0 0" }}
              >
                <div >
                  <div className="nav-primary">
                    <ul>
                      <li>
                        <button className="cust-button-bt">
                          <NavLink to="/contact" style={{ color: "white" }}>Contact Us</NavLink>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </header>


    </div>
  );
};

export default Navbar;
