import React, { Suspense, lazy } from 'react';
import Navbar from './Components/Navbar/Navbar';
import Mobile from './Components/Navbar/Mobile';
import { Route, Routes } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTop';
import MetaUpdater from './Components/Navbar/MetaUpdate'

// Lazy load the components
const Home = lazy(() => import('./Pages/Home'));
const About = lazy(() => import('./Pages/About'));
const Interior = lazy(() => import('./Pages/InteriorEmulsions'));
const InteriorTrend = lazy(() => import('./Pages/InteriorTrend'));
const InteriorLuxury = lazy(() => import('./Pages/InteriorLuxury'));
const InteriorRapid = lazy(() => import('./Pages/InteriorRapid'));
const ExteriorEmulsion = lazy(() => import('./Pages/ExteriorEmulsions'));
const ExteriorCanny = lazy(() => import('./Pages/ExteriorCanny'));
const ExteriorCosmic = lazy(() => import('./Pages/ExteriorCosmic'));
const ExteriorLegend = lazy(() => import('./Pages/ExteriorLegend'));
const ExteriorPolaris = lazy(() => import('./Pages/ExteriorPolaris'));
const Primers = lazy(() => import('./Pages/Primers'));
const PrimerVision = lazy(() => import('./Pages/PrimerVision'));
const PrimerLeoBasic = lazy(() => import('./Pages/PrimerLeoBasic'));
const PrimerVenus = lazy(() => import('./Pages/PrimerVenus'));
const PrimerChampion = lazy(() => import('./Pages/PrimerChampion'));
const Enamels = lazy(() => import('./Pages/Enamels'));
const EnamelsGlance = lazy(() => import('./Pages/EnamelsGlance'));
const EnamelsLennox = lazy(() => import('./Pages/EnamelsLennox'));
const EnamelsLoyal = lazy(() => import('./Pages/EnamelsLoyal'));
const EnamelsMarine = lazy(() => import('./Pages/EnamelsMarine'));
const EnamelsPride = lazy(() => import('./Pages/EnamelsPride'));
const EnamelsRado = lazy(() => import('./Pages/EnamelsRado'));
const SpecialProducts = lazy(() => import('./Pages/SpecialProducts'));
const SpecialAriel = lazy(() => import('./Pages/SpecialAriel'));
const SpecialClimate = lazy(() => import('./Pages/SpecialClimateGuard'));
const SpecialMajestic = lazy(() => import('./Pages/SpecialMajestic'));
const Blogs = lazy(() => import('./Pages/Blog'));
const BlogDet = lazy(() => import('./Pages/Blogdetail'));
const Dealer = lazy(() => import('./Pages/Dealer'));
const Contact = lazy(() => import('./Pages/Contact'));

const App = () => {
  return (
    <div>
      <ScrollToTopButton />
      <MetaUpdater />
      <Navbar />
      <Mobile />
      <Suspense fallback={<div className='text-center text-bg-danger text-white'>Loading...</div>}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/interior-emulsions" element={<Interior />} />
          <Route exact path="/interior-emulsions/trend" element={<InteriorTrend />} />
          <Route exact path="/interior-emulsions/revlon-luxury" element={<InteriorLuxury />} />
          <Route exact path="/interior-emulsions/rapid" element={<InteriorRapid />} />
          <Route exact path="/exterior-emulsions" element={<ExteriorEmulsion />} />
          <Route exact path="/exterior-emulsions/canny" element={<ExteriorCanny />} />
          <Route exact path="/exterior-emulsions/cosmic" element={<ExteriorCosmic />} />
          <Route exact path="/exterior-emulsions/legend" element={<ExteriorLegend />} />
          <Route exact path="/exterior-emulsions/polaris" element={<ExteriorPolaris />} />
          <Route exact path="/primers" element={<Primers />} />
          <Route exact path="/primers/vision" element={<PrimerVision />} />
          <Route exact path="/primers/leo-basic" element={<PrimerLeoBasic />} />
          <Route exact path="/primers/venus" element={<PrimerVenus />} />
          <Route exact path="/primers/champion" element={<PrimerChampion />} />
          <Route exact path="/enamels" element={<Enamels />} />
          <Route exact path="/enamels/glance" element={<EnamelsGlance />} />
          <Route exact path="/enamels/lennox" element={<EnamelsLennox />} />
          <Route exact path="/enamels/loyal" element={<EnamelsLoyal />} />
          <Route exact path="/enamels/marine" element={<EnamelsMarine />} />
          <Route exact path="/enamels/pride" element={<EnamelsPride />} />
          <Route exact path="/enamels/rado" element={<EnamelsRado />} />
          <Route exact path="/special-products" element={<SpecialProducts />} />
          <Route exact path="/special-products/aerial" element={<SpecialAriel />} />
          <Route exact path="/special-products/climate-guard" element={<SpecialClimate />} />
          <Route exact path="/special-products/majestic" element={<SpecialMajestic />} />
          <Route exact path="/Blogs" element={<Blogs />} />
          <Route exact path="/Blogs/which-paint-is-best-for-homes-in-india" element={<BlogDet />} />
          <Route exact path="/dealer-enquiry" element={<Dealer />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
