import React, { useState } from 'react';
import '../../App.css';
import logo from '../../Assets/mobile-logo.png'; // Adjust this path if needed
// import { NavLink } from 'react-router-dom';
import '../../Components/Navbar/Mobile.css'; // Ensure this file exists and is correctly imported

const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  return (
    <header className="mh-mobile-header">
      <div className="mh-header-container">
        <div className="logo">
          <a href="/"><img src={logo} alt="Logo" /></a>
        </div>
        <button className="mh-nav-icon" onClick={toggleMenu}>
          ☰
        </button>
      </div>
      {menuOpen && (
        <nav className="mh-menu">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About us</a></li>
            <li>
              <button className="mh-dropdown-btn" onClick={() => toggleDropdown('services1')}>
              <a href="/interior-emulsions">Interior-Emulsions</a> {openDropdown === 'services1' ? "▲" : "▼"}
              </button>
              {openDropdown === 'services1' && (
                <ul className="mh-dropdown">
                  <li><a href="/interior-emulsions/trend"><b>Trend</b> Classic interior</a></li>
                  <li><a href="/interior-emulsions/revlon-luxury"><b>Revlon</b> Luxury Interior</a></li>
                  <li><a href="/interior-emulsions/rapid"><b>Rapid</b> primium Interior</a></li>
                </ul>
              )}
            </li>
            <li>
              <button className="mh-dropdown-btn" onClick={() => toggleDropdown('services2')}>
                <a href="/exterior-emulsions">Exterior Emulsions</a> {openDropdown === 'services1' ? "▲" : "▼"}
              </button>
              {openDropdown === 'services2' && (
                <ul className="mh-dropdown">
                  <li><a href="/exterior-emulsions/cosmic"><b>Cosmic</b> Classic interior</a></li>
                  <li><a href="/exterior-emulsions/canny"><b>Canny</b> Luxury Interior</a></li>
                  <li><a href="/exterior-emulsions/polaris"><b>Polaris</b> primium Interior</a></li>
                  <li><a href="/exterior-emulsions/legend"><b>Legend</b> Multi</a></li>
                </ul>
              )}
            </li>
            <li>
              <button className="mh-dropdown-btn" onClick={() => toggleDropdown('services3')}>
                <a href="/primers">Primers</a> {openDropdown === 'services3' ? "▲" : "▼"}
              </button>
              {openDropdown === 'services3' && (
                <ul className="mh-dropdown">
                  <li><a href="/primers/vision"><b>Vision</b> Interior</a></li>
                  <li><a href="/primers/leo-basic"><b>Leo-</b> Basic</a></li>
                  <li><a href="/primers/venus"><b>Venus</b> Exterior</a></li>
                  <li><a href="/primers/champion"><b>Champion</b> Multi</a></li>
                </ul>
              )}
            </li>
            <li>
              <button className="mh-dropdown-btn" onClick={() => toggleDropdown('services4')}>
              <a href="/primers">Enamels - Oil Based</a>{openDropdown === 'services4' ? "▲" : "▼"}
              </button>
              {openDropdown === 'services4' && (
                <ul className="mh-dropdown">
                  <li><a href="/enamels-oil-based/rado-pu-enamel"><b>Rado</b> Pu Enamel</a></li>
                  <li><a href="/enamels-oil-based/pride-wood-primer"><b>Pride</b> Wood Primer</a></li>
                  <li><a href="/enamels-oil-based/lennox-floor-coat-red-primer"><b>Lennox</b> Floor coat red Primer</a></li>
                  <li><a href="/enamels-oil-based/glance-synthatic-enamel"><b>Glance</b> Synthatic Enamel</a></li>
                  <li><a href="/enamels-oil-based/marine-zinc-yellow-chromate-primer"><b>Marine</b> Zinc Yellow Chromate Primer</a></li>
                  <li><a href="/enamels-oil-based/loyal-red-oxide-metal-primer"><b>Loyal</b> Red Oxide Metal Primer</a></li>
                 
                </ul>
              )}
            </li>
            <li>
              <button className="mh-dropdown-btn" onClick={() => toggleDropdown('services5')}>
              <a href="/special-products">Special Products</a> {openDropdown === 'services5' ? "▲" : "▼"}
              </button>
              {openDropdown === 'services5' && (
                <ul className="mh-dropdown">
                  <li><a href="/special-products/aerial"><b>Aerial</b> Exterior Damp Proof</a></li>
                  <li><a href="/special-products/climate-guard"><b>Climate Guard</b> Exterior Weather Guard</a></li>
                  <li><a href="/special-products/majestic"><b>Majestic</b> Wall putty White Cement Based</a></li>
                </ul>
              )}
            </li>
            <li><a href="/dealer-enquiry">Dealer Enquiry</a></li>
            <li><a href="/download-catalog">Download-Catalog</a></li>
            <li><a href="/blogs">Blogs</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default MobileHeader;
