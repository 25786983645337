// MetaUpdater.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { metaConfig } from './MetaConfig';

const MetaUpdater = () => {
  const location = useLocation();
  const { pathname } = location;
  const meta = metaConfig[pathname] || {
    title: 'Choose The Best Wall Paint Company In India For Best Paints',
    description: ' Explore Our Range of Water-Based and Rust-Proof paints From Revlon Paints. Check Out And Buy Now',
    keywords: 'Best Wall Paint Company In India, Resistant Paint, Best exterior paint, Water-based primers, Enamel paint colours',
  };

  return (
    <Helmet>
      <title>Revlon Paints</title>
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
    </Helmet>
  );
};

export default MetaUpdater;
